import styled from "styled-components";
import colors from "../../brand/colors";

const CtaButtonStyle = styled.a`
  display: flex;
  background-color: ${colors.magenta["500"]};
  border-radius: 100px;
  padding: 15px 40px;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  max-width: max-content;
  margin-inline: auto;
  text-decoration: none;
  cursor: pointer;
  margin-block: 30px;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow 350ms;
  
  &.left{
    margin-inline: 0 auto;
    @media (max-width: 960px) {
      margin-inline: auto;
    }
  }
  
  
  &:hover{
    box-shadow: 0 3px 5px ${colors.magenta["500"]}77;
    transition: box-shadow 350ms;
  }
  
  &:hover img{
    transform: scale(1.3) rotate(-8deg);
    transition: transform 350ms;
  }
`

const CtaImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  transition: transform 350ms;
  transform: none;
`

export {
    CtaImage,
    CtaButtonStyle
}
