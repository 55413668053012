import whatsappIcon from '../../Assets/whatsapp.svg';
import { WhatsappButtonContainer, LeafContainer, TextButton} from './whatsapp.style'

const WhatsappButton = () => {
    return <>
        <WhatsappButtonContainer href="https://wa.me/+5216188016438" target="_blank" rel="noreferrer noopener">
            <LeafContainer>
                <img src={whatsappIcon} aria-hidden={true} alt="Whatsapp icon"/>
            </LeafContainer>
            <TextButton>
                Chatea con nosotros
            </TextButton>
        </WhatsappButtonContainer>
    </>
}

export default WhatsappButton;
