import {Logo} from "../Hompage";
import NidahLogo from "../../Assets/logo.svg"
import {HeaderContainer, HeaderTitle} from "./HeaderStyles";
import CtaButton from "../CtaButton";

const Header = () => {
    return <HeaderContainer>
        <div>
            <Logo to={"/"}>
                <img src={NidahLogo} alt=""/>
            </Logo>
            <HeaderTitle>Hospedaje Inteligente</HeaderTitle>
        </div>
        <CtaButton to={"https://live.ipms247.com/booking/book-rooms-nidahcondominios"} text={"Reserva ahora"}/>
    </HeaderContainer>
}

export default Header;
