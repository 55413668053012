import {CtaImage, CtaButtonStyle} from "./CtaButtonStyle";

const CtaButton = ({to, text, icon, className}) => {
    return <CtaButtonStyle className={className && className} href={to} rel={'noreferrer noopener'}>
        {icon && <CtaImage src={icon}/>}
        <span>{text}</span>
    </CtaButtonStyle>
}

export default CtaButton;
