import styled from "styled-components";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  
  @media(max-width: 1200px){
    padding-inline: 15px;
  }
`

export default Container;
