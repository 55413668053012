import servicesIcons from "./icons";
import {images} from "./images";

const happy_portada = images.happy[0];
const chill_portada = images.chill[0];
const cool_portada = images.cool[0];
const relax_portada = images.relax[0];
const gallery_happy = [images.happy[1], images.happy[2], images.happy[3], images.happy[4]]
const gallery_chill = [images.chill[1], images.chill[2], images.chill[3], images.chill[4]]
const gallery_cool = [images.cool[1], images.cool[2], images.cool[3], images.cool[4]]
const gallery_relax = [images.relax[1], images.relax[2], images.relax[3], images.relax[4]]

const [wifi, laundry, air, coffee, lock, roof, refrigerator] = servicesIcons;
const mainServices = [
  {
    name: 'Wifi',
    icon: wifi
  },
  {
    name: 'Lavandería',
    icon: laundry
  },
  {
    name: 'Roof Garden',
    icon: roof
  },
  {
    name: 'Mini split',
    icon: air
  },
  {
    name: 'Cocina y utensilios',
    icon: coffee
  },
  {
    name: 'Lockers',
    icon: lock
  },
]



const mainData = {
    title:{
        "main-page": 'Nidah es hospedaje inteligente',
        "basic-room": 'Habitación básica en Nidah hospedaje inteligente',
        "medium-room": 'Habitación media en Nidah hospedaje inteligente',
        "big-room": 'Habitación grande en Nidah hospedaje inteligente',
        "contact-page": '¿Tienes alguna duda? Contáctanos'
    },
    room: [
        {
            name: 'Happy',
            description: 'Ideal para 2 huéspedes, estos apartamentos te harán sentir calidez de principio a fin, obtén un descanso garantizado donde podrás gozar del silencio y comodidad que pueden ofrecerte. El departamento cuenta con cocineta, refrigerador, cafetera, y todo lo básico para cocinar. Además tienes un área de trabajo en el interior del departamento y un amplio baño con amenidades y agua caliente.',
            "main-image": happy_portada,
            services: mainServices,
            price: 850,
            "bed-number": 1,
            "max-host": 2,
            gallery: gallery_happy,
            "airbnb-url": "https://live.ipms247.com/booking/book-rooms-nidahcondominios"
        },
        {
            name: 'Chill',
            description: 'Amplios espacios pensados especialmente para una cómoda estancia de 2 huéspedes, super cómoda cama queen size y todo lo necesario para un buen descanso. El departamento cuenta con cocineta, refrigerador, cafetera, y todo lo básico para cocinar. Además, tienes un área de trabajo en el interior del departamento y un amplio baño con amenidades y agua caliente, además disfruta de un balcón y admira los atardeceres en la ciudad.',
            "main-image": chill_portada,
            services: mainServices,
            price: 980,
            "bed-number": 1,
            "max-host": 2,
            gallery: gallery_chill,
            "airbnb-url": "https://live.ipms247.com/booking/book-rooms-nidahcondominios"
        },
        {
            name: 'Cool',
            description: 'Amplios espacios pensados especialmente para una cómoda estancia de 2 huéspedes, super cómoda cama queen size y todo lo necesario para un buen descanso. El departamento cuenta con cocineta, refrigerador, cafetera, y todo lo básico para cocinar. Además, tienes un área de trabajo en el interior del departamento y un amplio baño con amenidades y agua caliente.',
            "main-image": cool_portada,
            services: mainServices,
            price: 980,
            "bed-number": 1,
            "max-host": 2,
            gallery: gallery_cool,
            "airbnb-url": "https://live.ipms247.com/booking/book-rooms-nidahcondominios"
        },
        {
            name: 'Relax',
            description: 'Nuestro espacio “Relax Nidah” te ofrece una experiencia única, ideal para tus vacaciones familiares o en grupo de hasta 4 huéspedes en 2 habitaciones separadas, ofrece 2 camas individuales y una cama queen size, espacio perfecto para compartir, con un cómodo y seguro balcón para disfrutar de los hermosos atardeceres y cielos espectaculares que nos regala la ciudad. El departamento cuenta con cocineta, refrigerador, cafetera, y todo lo básico para cocinar, una estancia para ver TV y comedor para 4.',
            "main-image": relax_portada,
            services: mainServices,
            price: 1115,
            "bed-number": 3,
            "max-host": 4,
            gallery: gallery_relax,
            "airbnb-url": "https://live.ipms247.com/booking/book-rooms-nidahcondominios"
        }
    ],
    social: {
        facebook: 'https://facebook.com/nidahmx',
        instagram: 'https://instagram.com/nidahmx',
        airbnb: 'https://airbnb.com/profile/nidahmx',
        email: 'contact@nidahmx.com'

    },
    "site-info": {
        description: "Hospedaje de primer nivel",
        keywords: [
            'Hospedaje',
            'Inteligente',
            'Durango',
            'Nidah',
            'Visita',
            'Ejecutivo',
            'Airbnb'
        ],
        "main-site": 'Te ofrecemos hospedaje autosuficiente de primer nivel, con el mayor confort e instalaciones de primera.',
    },
    routes: {
        "main-page": "/",
        "happy-room": "/room/happy",
        "chill-room": "/room/chill",
        "cool-room": "/room/cool",
        "relax-room": "/room/relax",
        "404": "/not-found"
    }
}


export default mainData;

export const dictionary = {
    "small-room": 0,
    "medium-room": 1,
    "big-room": 2
}
