import MainStyles from "./MainStyles";
import {BrowserRouter, Routes, Route, Outlet} from 'react-router-dom';
import RoomPage from "./Pages/Roompage"
import Header from "./components/Header";
import Main from "./Pages/Main";
import WhatsappButton from "./components/WhatsappButton";

const App = () => {

  return <>
    <BrowserRouter>
      <Header/>
      <MainStyles/>
      <Routes>
        <Route path="/" element={<Main/>}/>
        <Route path="room" element={<Outlet/>}>
          <Route path=":roomName" element={<RoomPage/>}/>
        </Route>
        <Route path="/main" element={<Main/>}/>

      </Routes>
    </BrowserRouter>
    <WhatsappButton/>
  </>

}

export default App;
