import styled from "styled-components";
import colors from "../../brand/colors";

const SectionDescription = styled.p`
  color: ${colors.white};
  line-height: 140%;
  font-size: 18px;
  position: relative;
  color: ${colors.white};
  
  &.center{
    max-width: 65ch;
    text-align: center;
    margin-inline: auto;
    margin-bottom: 30px;
  }
  @media (max-width: 960px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
`

export default SectionDescription;
