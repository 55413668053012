import styled from "styled-components";

const SectionSquares = styled.section`
    margin-block: 80px;
    
    
    
    & div.grid{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;
      align-items: center;
      height: min-content;
    }
    
    & div.squares{
      display: grid;
      grid-template-columns: repeat(2,1fr);
      gap: 20px;
      grid-auto-rows: min-content;
      height: auto;
    }
  
  @media (max-width: 960px){
    margin-block: 30px;
    & div.grid{
      grid-template-columns: 1fr;
      max-width: 550px;
    }
    
  }
`

export default SectionSquares;
