import styled from "styled-components";
import colors from "../../brand/colors";

const RoomTitle = styled.h1`
  color: ${colors.golden["500"]};
  position: sticky;
  margin-block: 0;
  top: 25vh;
  left: 20px;
  height: 75vh;
  text-align: right;
  width: 60px;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  border-left: 2px solid ${colors.golden["500"]};
  box-sizing: border-box;
  
  @media (max-width: 680px){
    writing-mode: horizontal-tb;
    transform: none;
    position: static;
    height: 100%;
    width: 100%;
    text-align: center;
    border-left: none;
    border-bottom: 2px solid ${colors.golden["500"]};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TitleContainer = styled.header`
  position: sticky;
  top: 25vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  animation: 1s titleswipe forwards ease-out;
  opacity: 0;
  
  @media (max-width: 680px){
    height: 100px;
    position: static;
    align-items: center;
  }
  
 @keyframes titleswipe {
   0%{
     opacity: 0;
     top: 300px;
   }
   100%{
     opacity: 1;
     top: 0;
   }
 } 
`
export {RoomTitle, TitleContainer}
