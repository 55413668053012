import {Description, Container, InfoContainer} from "./RoomContentStyle";
import { RoomInfo } from "./RoomInfo"
import beds from "./../../Assets/double-bed.svg"
import hosts from "./../../Assets/user.svg"
import dollar from "./../../Assets/moon.svg"
import SectionTitle from "../SectionTitle";
import Services from "./Services";
import CtaButton from "../CtaButton";
import Slider from "./../Slider"

const RoomContent = ({data}) => {
    console.log(data)
    console.log(data.gallery)
    return <Container>
        <Slider className={"nocurve"} images={data.gallery}/>
        <InfoContainer>
            <RoomInfo
                number={data["bed-number"]}
                title={'Número de camas'}
                icon={beds}
                className={"bed"}
            />
            <RoomInfo
                number={data["max-host"]}
                title={'Huéspedes'}
                icon={hosts}
                copies={data["max-host"]}
            />
        </InfoContainer>

        <Description>
            {data.description}
        </Description>

        <CtaButton text={'Reservar'} to={data["airbnb-url"]}/>

        <SectionTitle text={"Servicios"}/>
        <Services services={data.services}/>

        <SectionTitle text={"Ubicación"}/>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1315.8901513921687!2d-104.64991962270953!3d24.0263145951904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bb7dd62785123%3A0xda0ff4746d488f22!2sAgricultura%20108%2C%20Bur%C3%B3crata%2C%2034279%20Durango%2C%20Dgo.!5e0!3m2!1sen!2smx!4v1657774852410!5m2!1sen!2smx"
            style={{"border": "none", "width":"100%", "maxWidth":"720px", "marginInline":"auto", "display":"block", "height": "350px", "borderRadius":"8px"}} loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"/>

        <CtaButton text={'Reservar'} to={data["airbnb-url"]}/>
    </Container>
}

export {
    RoomContent
}
