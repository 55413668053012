import {CardContainer, CardImage, CardTitle, CardDescription, CardInfo, CardIcon, CardIconContainer, FlexIcon, CardButton} from "./cardStyle";
import bed from './../../Assets/double-bed.svg'
import host from './../../Assets/teamwork.svg'

const CardRoom = ({roomInfo}) => {

    return <CardContainer to={`/room/${roomInfo.name.toLowerCase()}`}>
        <CardImage src={roomInfo["main-image"]}  alt={roomInfo.name} />
        <div>
            <CardInfo>
                <CardTitle>{roomInfo.name}</CardTitle>
                <CardIconContainer>
                    <FlexIcon>
                        <CardIcon className={"bed"} src={bed} aria-hidden={"true"} alt="icono de cama" className={'bed'}/>
                        <p aria-label={'Numero de habitaciones disponibles'}>{roomInfo['bed-number']}</p>
                    </FlexIcon>
                    <FlexIcon>
                        <CardIcon src={host} aria-hidden={"true"} alt="icono de persona"/>
                        <p aria-label={'Numero de personas que pueden estar en la habitación'}>{roomInfo['max-host']}</p>
                    </FlexIcon>
                </CardIconContainer>

            </CardInfo>
            <CardDescription>{roomInfo.description}</CardDescription>

            <CardButton>Ver apartamento</CardButton>
        </div>
    </CardContainer>
}

export default CardRoom;

/* <CardStyle to={`/room/${roomInfo.name.toLowerCase()}`} time={time}>
        <img src={roomInfo["main-image"]}  alt=""/>
        <ScreenContainer>
            <CardTitle>{roomInfo.name}</CardTitle>
            <CardDescription>{roomInfo.description}</CardDescription>
            <FooterCard>
                <ServiceGrid>
                    <Service>
                        <img src={bed} aria-hidden={"true"} alt="icono de cama" className={'bed'}/>
                        <p aria-label={'Numero de habitaciones disponibles'}>{roomInfo['bed-number']}</p>
                    </Service>
                    <Service>
                        <img src={host} aria-hidden={"true"} alt="icono de persona"/>
                        <p aria-label={'Numero de personas que pueden estar en la habitación'}>{roomInfo['max-host']}</p>
                    </Service>
                </ServiceGrid>
                <ViewRoomButton to={`/room/${roomInfo.name.toLowerCase()}`}>
                    Ver habitación
                </ViewRoomButton>
            </FooterCard>
        </ScreenContainer>
    </CardStyle> */
