import styled from 'styled-components';
import colors from "../../brand/colors";
import {Link} from "react-router-dom";

const CardContainer = styled(Link)`
  padding: 8px;
  border-radius: 20px;
  text-decoration: none;
  display: grid;
  gap: 20px;
  cursor: pointer;
  border: 1px solid #fff2;
`

const CardImage = styled.img`
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  object-fit: cover;
`

const CardTitle = styled.h2`
  color: ${colors.golden["500"]};
  font-size: 24px;
  margin: 0;
`

const CardDescription = styled.p`
  color: ${colors.white};
  line-height: 140%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-block: 20px;
`

const CardInfo = styled.footer`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`

const CardIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  
  &.bed{
    width: 24px;
    height: 24px;
    padding: 4px;
  }
`

const CardIconContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 8px;
`

const FlexIcon = styled.div`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  gap: 10px;
  
  p{
    margin: 0;
    font-size: 14px;
  }
`

const CardButton = styled.button`
  color: ${colors.golden["500"]};
  border: 1px solid ${colors.golden["500"]};
  border-radius: 40px;
  background-color: transparent;
  padding: 10px;
  cursor: pointer;
  
  &:hover{
    background-color: ${colors.golden["500"]};
    color: ${colors.carbon["500"]}
  }
`

export {
    CardContainer,
    CardImage,
    CardTitle,
    CardDescription,
    CardInfo,
    CardIcon,
    CardIconContainer,
    FlexIcon,
    CardButton
}


