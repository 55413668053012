import styled from "styled-components";
import colors from "../../brand/colors";

const H2 = styled.h2`
  max-width: 720px;
  margin-inline: auto;
  color: ${colors.golden["500"]};
  border-bottom: 1px solid ${colors.golden["500"]};
  padding-bottom: 10px;
  font-size: 28px;
  font-weight: 600;
  margin-top: 50px;
  
  @media (max-width: 680px){
    margin-inline: 15px;
  }
  
  
`

export {
    H2
}
