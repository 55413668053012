import styled from "styled-components";

const ServicesContainer = styled.section`
  display: grid;
  //grid-template-columns: repeat(auto-fit, minmax(20px, 90px));
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 700px;
  margin-inline: auto;
  padding-inline: 15px;
  justify-content: center;
  
  @media (min-width: 480px){
    grid-template-columns: repeat(auto-fit, minmax(20px, 90px));
  }
`

const Service = styled.article`
    
  
  & img{
    max-width: 100%;
    display: block;
    padding: 25px;
    box-sizing: border-box;
    }
  
  & span{
    display: block;
    text-align: center;
    color: white;
    font-size: 16px;
  }
`

export {
    ServicesContainer,
    Service
}
