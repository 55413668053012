import {Helmet} from "react-helmet";
import maindata from "../../__mock__/maindata";
import {
    Banner,
    MainContainer,
    MainTitle,
    Screen,
    Facade,
    Model,
    SectionSquares,
    SecondaryImageB,
    SecondaryImageA,
    SectionTitle,
    SectionDescription,
    Container
} from "../components/Main";
import Slider from "../components/Slider"
import CtaButton from "../components/CtaButton";
import CardRoom from "../components/cardRoom";
import {images} from "../../__mock__/images";


const gridStyle = {
    'display': 'grid',
    'gridTemplateColumns': 'repeat(auto-fit, minmax(250px, 1fr))',
    'gap': '20px',
    'width': '95%',
    'maxWidth': '1200px',
    'margin': 'auto'
}

const Main = () => {
    return (
        <>
            <Banner background="./static/mainpage/banner.jpg">
                <Screen>
                    <MainContainer>
                        <MainTitle>
                            <span>Una nueva alternativa</span>
                            <br/>de Hospedaje Inteligente
                            <br/>en Durango
                        </MainTitle>
                    </MainContainer>
                </Screen>
            </Banner>
            <MainContainer>
                <Container>
                    <SectionSquares>
                        <div className="grid">
                            <div>
                                <SectionTitle>Un condominio diseñado en un concepto moderno, que privilegia la privacidad y la comodidad</SectionTitle>
                                <SectionDescription>Situado en un lugar de fácil acceso y seguro dentro de la ciudad, disfruta de un alojamiento con llegada totalmente autónoma, diseñado para brindarte una cálida estancia con un ambiente relajado, cómodo y seguro; a tan solo 5 minutos de zona centro a unos pasos vialidades que te conectan con toda la ciudad. </SectionDescription>
                                <CtaButton className="left" to={"/main"} text={"Descubre nuestros espacios"}/>
                            </div>
                            <div className="squares">
                                <Facade>
                                    <img src="./static/mainpage/fachada.png" alt="Fachada"/>
                                </Facade>
                                <SecondaryImageA>
                                    <img src="./static/mainpage/roof.jpg" alt="Roofgarde"/>
                                </SecondaryImageA>
                                <SecondaryImageB>
                                    <img src="./static/mainpage/laundry.jpg" alt="Laundry"/>
                                </SecondaryImageB>
                                <Model>
                                    <img src="./static/mainpage/model.png" alt="Model"/>
                                </Model>
                            </div>
                        </div>
                    </SectionSquares>
                </Container>
            </MainContainer>
            <Container>
                <SectionTitle className={"center"}>Nuestros apartamentos</SectionTitle>
                <SectionDescription className={"center"}>La privacidad y comodidad son nuestro valuarte, en cada uno de nuestros espacios encontrarás una experiencia de primer nivel, desde el primer instante. Nuestros sistemas de acceso están 100% digitalizados, olvidate de las llaves o de esperar en recepción.</SectionDescription>
                <div style={gridStyle}>
                    {
                        maindata.room.map((room, index) => {
                            return <CardRoom key={`room_card_${index}`} time={(index + 1)*.75} roomInfo={room}/>
                        })
                    }
                </div>
            </Container>
            <Container>
                <SectionTitle className={"center"}>Nuestros espacios</SectionTitle>
                <SectionDescription className={"center"}>Nuestros espacios son de primer nivel y están diseñados para maximizar el confort y garantizar una experiencia óptima cada momento</SectionDescription>
                <Slider images={images.cool}/>
            </Container>
            <Container>
                <SectionTitle className={"center"}>¿Dónde estamos?</SectionTitle>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1315.8901513921687!2d-104.64991962270953!3d24.0263145951904!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869bb7dd62785123%3A0xda0ff4746d488f22!2sAgricultura%20108%2C%20Bur%C3%B3crata%2C%2034279%20Durango%2C%20Dgo.!5e0!3m2!1sen!2smx!4v1657774852410!5m2!1sen!2smx"
                    style={{"border": "none", "width":"100%", "maxWidth":"720px", "marginInline":"auto", "display":"block", "height": "350px", "borderRadius":"8px"}} loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"/>
            </Container>
        </>
    )
}

export default Main;
