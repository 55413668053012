import styled from "styled-components";

const Banner = styled.header`
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: top center;
  height: 600px;
  position: relative;
  
`

export default Banner;
