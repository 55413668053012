import {InfoItem} from "./RoomContentStyle";

const RoomInfo = ({number, title, icon, className, copies}) => {

    const iconString = `${icon}@`;
    console.log(icon)
    console.log(copies)
    console.log(iconString.repeat(copies).split('@'))
    return <>
        <InfoItem >
            <img className={`${className && className} ${copies? "items"+copies:null}`} src={icon} alt={title}/>
            <p>
                <span>
                    {number}
                </span>
            </p>
        </InfoItem>
    </>
}

export {
    RoomInfo
}
