import styled from "styled-components";
import colors from "../../brand/colors";
import {Link} from "react-router-dom";

const Homepage = styled.main`
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: top center;
  height: 100vh;
  width: 100vw;
`

const Screen = styled.div`
  background-image: linear-gradient(to top, ${colors.carbon["500"]} , ${colors.carbon["500"]}ef);
  backdrop-filter: saturate(0);
  height: 100%;
`

const Logo = styled(Link)`
  display: block;
  width: 90px;
  padding-block: 20px;
  
  & img{
    max-width: 100%;
    height: auto;
    margin: auto;
    display: block;
  }
`

const HomeTitle = styled.h2`
  font-size: 28px;
  color: ${colors.golden["500"]};
  font-weight: normal;
  max-width: 90%;
  margin: 0 auto;
  line-height: 140%;
`

const HomeDescription = styled.p`
  color: white;
  line-height: 140%;
  max-width: min(90%, 75ch);
  margin: 15px auto;
`

export {
    Homepage,
    Screen,
    Logo,
    HomeTitle,
    HomeDescription
}
