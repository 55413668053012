import {useState, useEffect} from "react";
import {
  SliderContainer,
  SliderClipper,
  Image
} from "./SliderStyle";

const Slider = ({images, className}) => {

  const [gallery] = useState(images);
  const [count, setCounter] = useState(0);
  const [current, setCurrent] = useState(gallery[count]);

  useEffect(()=>{
    setTimeout(()=> {
      nextGalleryHandler()
    },3000)
  }, [current, count])

  const nextGalleryHandler = () => {
    if(count < gallery.length - 1){
      setCounter(count + 1);
      setCurrent(gallery[count]);
    }else{
      setCounter(0);
      setCurrent(gallery[count])
    }
  }

 /* const prevGalleryHandler = () => {
    if(count > 0){
      setCounter(count - 1);
      setCurrent(gallery[count]);
    }else{
      setCounter(gallery.length - 1);
      setCurrent(gallery[count])
    }
  }*/

  return (<>
    <SliderClipper className={className}>
      <SliderContainer>
        <Image src={current}  alt=""/>
      </SliderContainer>
    </SliderClipper>
  </>)

}

export default Slider;
