import happy_portada from "./../src/Assets/HappyNidah/HAPPY NIDAH 5_PORTADA.jpg";
import happy_01 from "./../src/Assets/HappyNidah/HAPPY NIDAH 5_01.jpg";
import happy_02 from "./../src/Assets/HappyNidah/HAPPY NIDAH 5_02.jpg";
import happy_03 from "./../src/Assets/HappyNidah/HAPPY NIDAH 5_03.jpg";
import happy_04 from "./../src/Assets/HappyNidah/HAPPY NIDAH 5_04.jpg";
import chill_portada from "../src/Assets/ChillNidah/CHILL NIDAH_PORTADA.jpg"
import chill_01 from "../src/Assets/ChillNidah/CHILL NIDAH_01.jpg";
import chill_02 from "../src/Assets/ChillNidah/CHILL NIDAH_02.jpg";
import chill_03 from "../src/Assets/ChillNidah/CHILL NIDAH_03.jpg";
import chill_04 from "../src/Assets/ChillNidah/CHILL NIDAH_04.jpg";
import cool_portada from "../src/Assets/CoolNidah/COOL NIDAH 9_PORTADA.jpg";
import cool_01 from "../src/Assets/CoolNidah/COOL NIDAH 9_01.jpg";
import cool_02 from "../src/Assets/CoolNidah/COOL NIDAH 9_02.jpg";
import cool_03 from "../src/Assets/CoolNidah/COOL NIDAH 9_03.jpg";
import cool_04 from "../src/Assets/CoolNidah/COOL NIDAH 9_04.jpg";
import relax_portada from "../src/Assets/RelaxNidah/RELAX NIDAH_PORTADA.jpg";
import relax_01 from "../src/Assets/RelaxNidah/RELAX NIDAH_01.jpg";
import relax_02 from "../src/Assets/RelaxNidah/RELAX NIDAH_02.jpg";
import relax_03 from "../src/Assets/RelaxNidah/RELAX NIDAH_03.jpg";
import relax_04 from "../src/Assets/RelaxNidah/RELAX NIDAH_04.jpg";

const images = {
  happy: [
    happy_portada,
    happy_01,
    happy_02,
    happy_03,
    happy_04
  ],
  chill: [
    chill_portada,
    chill_01,
    chill_02,
    chill_03,
    chill_04
  ],
  cool: [
    cool_portada,
    cool_01,
    cool_02,
    cool_03,
    cool_04
  ],
  relax: [
    relax_portada,
    relax_01,
    relax_02,
    relax_03,
    relax_04
  ]
}

export {
  images
}
