import styled from "styled-components";

const Facade = styled.div`
  display: block;
  background-image: url("./static/mainpage/sky.jpg");
  background-position: bottom center;
  border-radius: 25px;
  width: 100%;
  height: 240px;
  max-width: 100%;
  background-size: cover;
  position: relative;
  
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    border-radius: 0 0 25px 25px;
  }
  
  @media (max-width: 960px){
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    
    & img{
      height: 120%;
      width: 100%;
    }
  }
`

export default Facade;
