import styled from "styled-components";
import colors from "../../brand/colors";

const MainTitle = styled.h1`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: ${colors.white};
  position: relative;
  
 
  
  &::before{
    position: absolute;
    width: 500px;
    height: 500px;
    background-image: url("./static/mainpage/banner_deco.svg");
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    left: -50px;
    top: -100px;
    opacity: .2;
  }

  @media (max-width: 480px){
    font-size: 20px;

    &::before{
      position: absolute;
      width: 300px;
      height: 300px;
      background-image: url("./static/mainpage/banner_deco.svg");
      content: "";
      background-size: contain;
      background-repeat: no-repeat;
      opacity: .2;
      top: -50px;
      left: -20px;
    }
  }
`



export default MainTitle;
