import styled from "styled-components";

const RoomLayout = styled.main`
  display: grid;
  grid-template-columns: 100px 1fr;
  height: 100vh;
  gap: 30px;
  position: relative;
  
  @media (max-width: 680px){
    display: block;
    overflow: initial;
  }
`

export {
    RoomLayout
}
