import styled from "styled-components";

const SecondaryImageA = styled.div`
  display: block;
  width: 70%;
  aspect-ratio: 1/1;
  border-radius: 20px;
  overflow: hidden;
  justify-self: flex-start;
  align-self: flex-end;
  
  img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
`

const SecondaryImageB = styled.div`
  display: block;
  width: 70%;
  aspect-ratio: 1/1;
  border-radius: 20px;
  overflow: hidden;
  justify-self: flex-end;
  align-self: flex-start;
  
  img{
    max-width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: 960px) {
    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
  }
`

export {
    SecondaryImageA,
    SecondaryImageB
}
