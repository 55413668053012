import styled from "styled-components";

const LeafContainer = styled.div`
  background: #616769;
  width: 40px;
  height: 40px;
  border: 2px solid #25D366;
  border-radius: 0 30px 0 40px;
  transform: rotate(-65deg);
  position: absolute;
  left: -20px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  & > img{
    transform: rotate(65deg) translateY(-2px);
  }
`

const TextButton = styled.div`
  background: #616769;
  padding: 6px 12px 6px 35px;
  border-radius: 3px;
  font-size: 16px;
`

const WhatsappButtonContainer = styled.a`
  display: flex;
  max-width: max-content;
  position: fixed;
  right: 20px;
  bottom: 30px;
  color: white;
  text-decoration: none;
  box-shadow: 0 0 10px 1px #333;
`

export {
    WhatsappButtonContainer,
    LeafContainer,
    TextButton

}
