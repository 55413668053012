import styled from "styled-components";
import colors from "../../brand/colors";

const Container = styled.div`
  height: 100%;
  width: 100%;
  overflow: initial;
  
  @media (max-width: 680px){
    margin-top: 0;
    overflow: initial;
  }
`

const Description = styled.p`
  color: ${colors.golden["500"]};
  line-height: 1.4;
  max-width: 720px;
  margin-inline: auto;
  padding-inline: 20px;
  box-sizing: border-box;
  font-size: 16px;
`

const Slider = styled.div`
  width: 900px;
  height: 500px;
  background-color: ${colors.green["500"]};
  margin-inline: auto;
  border-radius: 20px;
  overflow: hidden;
  
  & .slider-img{
    min-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  @media (max-width: 960px){
    max-width: 100%;
    width: 100%;
    aspect-ratio: 16/9;
    height: auto;
    border-radius: 20px 0 0 20px;
  }
  
  @media (max-width: 680px){
    aspect-ratio: 4/3;
    border-radius: 0;
    margin-top: 0;
  }
  
  @media (max-width: 480px){
    aspect-ratio: 1/1;
  }
`

const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  
  @media (min-width: 680px){
    max-width: 400px;
    margin-inline: auto;
    margin-top: -50px;
    border-radius: 5px;
    overflow: hidden;
  }
  margin-bottom: 30px;
`

const InfoItem = styled.article`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  background-color: ${colors.gray["500"]};
  padding: 10px;
  box-sizing: border-box;
  border: none;
  
  & p{
    margin: 0;
    display: flex;
    gap: 10px;
    color: white; 
    font-weight: normal;
    font-size: 16px;
  }
  
  & img{
    max-width: 30px;
    &.items2{
      position: relative;
      left: -15px;
      filter: drop-shadow(30px 0 0 #fff);
    }
    &.items4{
      position: relative;
      left: -40px;
      top: 5px;
      filter: drop-shadow(25px 0 0 #fff) drop-shadow(50px 0 #fff);
    }
    &.bed{
      width: 25px;
    }
  }
  
  @media (max-width: 680px){
    flex-direction: column;
    
    & img{
      max-width: 30px;
      
    }
  }
`

export {
    Description,
    Slider,
    Container,
    InfoItem,
    InfoContainer
}
