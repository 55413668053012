import styled from 'styled-components';

const MainContainer = styled.div`
  max-width: 1200px;
  margin-inline: auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
`

export default MainContainer;
