import {useEffect} from "react";
import styled from 'styled-components';

const SliderContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  width: 100%;
  height: 100%;
`

const SliderClipper = styled.div`
  display: block;
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
  border-radius: 20px;
  
  &.nocurve{
    border-radius: 0;
  }
  
  @media (min-width: 680px){
    aspect-ratio: 16/9;
    
    &.nocurve{
      border-radius: 20px;
    }
  }
`

const Imagen = styled.img`
  min-width: 100%;
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
  
`

const Image = ({src, alt}) => {

    useEffect(()=>{

        return ()=> {
            const image = document.getElementById('galleryImage');
            image.classList.add('out')
            image.classList.remove('out')
        }

    }, [src])

    return <Imagen id="galleryImage" src={src} alt={alt}/>
}

export {
    SliderContainer,
    SliderClipper,
    Image
}
