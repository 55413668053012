import TitleRoom from '../components/TitleRoom';
import { RoomLayout } from '../components/RoomLayout';
import { RoomContent } from '../components/RoomContent';
import {ToTop} from "../Helpers";

const Room = ({room}) => {
    ToTop();
    return <>
        <div className="mainLayout">
            <RoomLayout>
                <TitleRoom title={room.name}/>
                <RoomContent
                    data={room}
                />
            </RoomLayout>
        </div>
    </>

}

export default Room
