import {Helmet} from "react-helmet";
import {RoomTitle, TitleContainer} from "./TitleRoomStyle";

const TitleRoom = ({title}) => {
    return <>
        <Helmet>
            <title>Apartamento {title}</title>
        </Helmet>
        <TitleContainer>
            <RoomTitle>
                Apartamento {title}
            </RoomTitle>
        </TitleContainer>

    </>
}

export default TitleRoom;
