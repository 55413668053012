import {createGlobalStyle} from "styled-components";
import colors from './brand/colors';


const MainStyles = createGlobalStyle`
  
  body{
    background-color: ${colors.carbon["500"]};
    font-family: 'Maven Pro', sans-serif;
    margin: 0;
  }
  
  .mainLayout{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
`

export default MainStyles;
