import {useParams} from "react-router-dom";
import maindata from "../../__mock__/maindata";
const {room} = maindata;
import Room from "./Room";

const RoomPage = () => {
    const params = useParams();

    switch (params.roomName.toLowerCase()){
        case room[0].name.toLowerCase():
            return <Room room={room[0]}/>
        case room[1].name.toLowerCase():
            return <Room room={room[1]}/>
        case room[2].name.toLowerCase():
            return <Room room={room[2]}/>
        case room[3].name.toLowerCase():
            return <Room room={room[3]}/>
        default:
            return <h1>404 Page not found</h1>
    }
}

export default RoomPage
