import {ServicesContainer, Service} from "./ServicesStyles";

const Services = ({services}) => {
    return <ServicesContainer>
        {
            services.map((el, index) => {
                return <Service key={`${el.name}_${index}_services`}>
                    <img src={el.icon} alt={el.name}/>
                    <span>{el.name}</span>
                </Service>
            })
        }
    </ServicesContainer>
}

export default  Services;
