import styled from "styled-components";
import colors from "../../brand/colors";

const SectionTitle = styled.h2`
  color: ${colors.golden["500"]};
  line-height: 140%;
  font-weight: 500;
  font-size: 30px;
  
  &.center{
    text-align: center;
  }

  @media (max-width: 960px) {
    text-align: center;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`

export default SectionTitle;
