import {useLayoutEffect} from "react";

const ToTop = ( ) => {
  useLayoutEffect(()=>{
    window.scroll(0,0)
  },[])
}

export {
  ToTop
}
