import styled from 'styled-components';
import colors from "../../brand/colors";

const Screen = styled.div`
  background-color: ${colors.carbon["500"]}AA;
  width: 100%;
  height: 100%;
`

export default Screen;
