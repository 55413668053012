import wifi from "./../src/Assets/icons/wifi.svg";
import laundry from "./../src/Assets/icons/laundry.svg";
import air from "./../src/Assets/icons/air-heater.svg";
import coffee from "./../src/Assets/icons/cafetera.svg";
import lock from "./../src/Assets/icons/padlock.svg";
import roof from "./../src/Assets/icons/picnic.svg";
import refrigerator from "./../src/Assets/icons/refrigerator.svg";

const servicesIcons = [wifi, laundry, air, coffee, lock, roof, refrigerator];

export default servicesIcons;
