import styled from "styled-components";
import colors from "../../brand/colors";

const HeaderContainer = styled.header`
  max-width: 1200px;
  width: 100%;
  padding-inline: 15px;
  box-sizing: border-box;
  margin-inline: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  flex-shrink: 1;
  & a{
    margin: 0;
    justify-self: flex-end;
  }
  & div{
    display: flex;
    gap: 10px;
    align-items: flex-end;
  }

  @media (max-width: 640px){
    display: flex;
`

const HeaderTitle = styled.h1`
  font-size: 16px;
  margin: 0 0 0 10px;
  font-weight: normal;
  color: ${colors.golden["500"]};
  padding-block: 20px;
  
  @media (max-width: 640px){
    display: none;
  }
`

export {
    HeaderContainer,
    HeaderTitle
}
